import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

const LimitIncrease = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section id='digital-accoun' className='bg-sand py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-md-6 col-lg-4 offset-lg-3 order-md-last'>
            <div className='d-flex justify-content-center justify-content-lg-end mb-3 mb-md-0'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/banking-dobra6/image.webp'
                altDescription='Ilustração de um cofrinho no formato de porquinho.'
                arrayNumbers={[ 306, 306, 327, 385 ]}
              />
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-28 lh-md-34 lh-xl-52 text-grayscale--500 fw-500'>Aumente e tenha o limite de crédito que quiser</h2>
            <Link
              title='Saiba como aumentar seu limite '
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-2 mt-md-4'
              to='/pra-voce/cartoes/como-aumentar-limite/'
              target='_blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  element_action: 'click button',
                  element_name: 'Saiba como aumentar seu limite',
                  section_name: 'Aumente e tenha o limite de crédito que quiser',
                  redirect_url: `${window.location.origin}/pra-voce/cartoes/como-aumentar-limite/`,
                })
              }}
            >
              Saiba como aumentar seu limite
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default LimitIncrease
