import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

const DigitalAccount = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section id='digital-account' className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2 order-md-last'>
            <div className='d-flex justify-content-center justify-content-lg-end mb-3 mb-md-0'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/banking-dobra2/image.webp'
                altDescription='Homem acessando o Super App do Inter. Ao fundo está um prédio.'
                arrayNumbers={[ 261, 261, 374, 454 ]}
              />
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-28 lh-md-34 lh-xl-52 text-grayscale--500 fw-500'><span className='d-lg-block'>Conta Digital</span> Gratuita, segura e completa</h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-grayscale--400 mt-md-4'>A Conta Digital do Inter reúne todos os produtos bancários em um só lugar. Abra a sua e acesse Cartão, Pix, Recargas, Shopping e mais no Super App.</p>
            <Link
              title='Conheça nossa Conta Digital'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-2'
              to='/pra-voce/conta-digital/pessoa-fisica/'
              target='_blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  element_name: 'Conheça nossa Conta Digital',
                  section_name: 'Conta Digital Gratuita, segura e completa',
                  redirect_url: `${window.location.origin}/pra-voce/conta-digital/pessoa-fisica/`,
                })
              }}
            >
              Conheça nossa Conta Digital
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default DigitalAccount
