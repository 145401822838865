import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { Section } from './style'

const SalaryPortability = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <Section id='digital-accoun' className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-md-6'>
            <div className='d-flex justify-content-center justify-content-lg-start mb-3 mb-md-0'>
              {width < WIDTH_MD
                ? <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra10-mobile-banking/image.webp'
                    altDescription='Uma mulher acessando sua conta digital do Inter para pedir portabilidade de salário.'
                    arrayNumbers={[ 268, 261, 400, 400 ]}
                  />
                : <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/banking-dobra9/image.webp'
                    altDescription='Uma mulher acessando sua conta digital do Inter para pedir portabilidade de salário.'
                    arrayNumbers={[ 268, 347, 378, 455 ]}
                  />
              }
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-28 lh-md-34 lh-xl-52 text-grayscale--500 fw-500'>Peça a Portabilidade de Salário e receba no Inter</h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-grayscale--400 mt-3'>Recebendo seu salário direto no Inter, você controla melhor seu dinheiro, acumula pontos mais fácil e centraliza suas finanças no Super App.</p>
            <Link
              title='Conheça a Portabilidade de Salário'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none btn-orange--extra mt-2 mt-md-4'
              to='/pra-voce/portabilidade-de-salario/'
              target='_blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_09',
                  element_action: 'click button',
                  element_name: 'Conheça a Portabilidade de Salário',
                  section_name: 'Peça a Portabilidade de Salário e receba no Inter',
                  redirect_url: `${window.location.origin}/pra-voce/portabilidade-de-salario/`,
                })
              }}
            >
              Conheça a Portabilidade de Salário
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SalaryPortability
